.sty1{
    padding: 0px 0px 0px 0px;
}
.sty2{
    background-color: #f7f7f7 ;
}
.sty3{
    padding: 50px 20px 0px 20px;
}
.sty4{
    padding-bottom: 15px ;
}
.sty5{
    font-size: 20px ;
}
.sty6{
    max-width: 135px ;
}
.sty7{
    border-width: 2px;
}
.sty8{
    padding-bottom: 0px ;
}
.sty9{
    font-size: 16px ;text-transform: none ;
}
.sty10{
    font-size: 16px ;
}
.sty11{
    font-size: 16px ;width: 16px ;
}
.sty12{
    font-size: 16px ;
}
.sty13{
    font-size: 16px ;
}
.sty14{
    font-size: 16px ;width: 16px ;
}
.sty15{
    font-size: 16px ;
}
.sty16{
    padding: 50px 20px 0px 20px;
}
.sty17{
    padding-bottom: 15px ;
}
.sty18{
    font-size: 20px ;
}
.sty19{
    max-width: 270px ;
}
.sty20{
    border-width: 2px;
}
.sty21{
    display: none;
}
.sty22{
    padding: 0px 0px 0px 0px;
}
.sty23{
    padding-bottom: 0px ;
}
.sty24{
    text-transform: none ;
}
.sty25{
    border: 0; width: 100%; height: 400px;
}
