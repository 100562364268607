.carddm-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin: 0 -15px;
  }
  
  .carddm {
    width: 25%;
    padding: 0 20px;
    box-sizing: border-box;
    float: left;
    border-radius: 50px;
    margin-top: 20px;
  }
  
  .carddm img {
    width: 100%;
    height: 80%;
    display: block;
  }
  
  .carddm-info {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .carddm-info h3 {
    margin-top: 0;
  }
  
  .carddm-info p {
    margin-bottom: 0;
  }
.fluid{
    margin: 30px;
}  


@media only screen and (max-width: 600px) {
    .carddm {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-top: 10px;
      }
      
      .carddm img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      
      .carddm-info {
        padding: 20px;
        text-align: center;
      }
      
      .carddm-info h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      
      .carddm-info p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
      
      .carddm-info a {
        display: inline-block;
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border-radius: 20px;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.2s ease-in-out;
      }
      
      .carddm-info a:hover {
        background-color: #0062cc;
      }
      
}