.general{
    margin: 40px;
}


.blogs-group{
    display: flex;
    flex-direction: row;
    
    
}
.blogs-all{
    

}
.filter-blogs{
    border-radius: 10px;
}
.filter-search{
    border-radius: 20px;
    padding: 8px;
    width: 95%;
    margin-bottom: 20px;
}
.blog-name{
    text-align: center;
    /* color: rgb(16, 16, 103) !important; */
    padding: 30px;
    text-decoration:underline;
    text-transform: uppercase;
}
.all-filter-backgroud{
    background-color: #F2F2F2;
    border-radius: 20px 20px 0 0;
}
.all-filter{
    margin: 10px 20px 10px 20px;
    
}
.all-filter h5{
    color: rgb(16, 16, 103) !important;
}
.all-filter input{
    background-color: transparent;
    border-color: rgb(108, 65, 103);
    width: 100% !important;
    margin-top: 10px;
}
.all-filter a{
    text-decoration: none;
}
.mobile{
    display: none;
}
.connect h5{
    margin: 20px;
    color: rgb(16, 16, 103) !important;
}
.allicons{
    /* margin-left: 20PX; */
}
.allicons i{
    /* margin-left: 5px; */
    padding: 4px;
}
.iconback{
    margin-left: 15px;
    border: rgb(237, 7, 7) solid 1px;padding:1px;
    border-radius: 50%;
    padding: 8px;
    
}
.iconback :hover{
    padding: 12px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.26);
    
}
.line{
    border-bottom:1px solid rgba(11, 11, 11, 0.183);
}





.card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
  }
  
  .user {
    display: flex;
    gap: .5rem;
  }
  
  .user__image {
    border-radius: 50%;
  }
  
  .user__info > small {
    color: #666;
  }

/* ALL MOBILE RESPONSIVE */
@media screen and (max-width: 800px){
    .mobile{
        display: flex;
        flex-direction: column;
    }
    .laptop{
        display: none;
    }
    .filter-blogs{
        margin: 50px;
        border-radius: 20px;
    }
}