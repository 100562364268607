.a1 {
    padding: 90px 0px 90px 0px;
}

.a2{
    background-image: 
  url(
"../../../public/assets/wp-content/uploads/2022/01/about-us1.jpg");
  background-size: "cover";
  background-position: "center" ;
  height: 100vh;
  background-repeat:no-repeat;
}

.a3 {
    font-size:30px;
    font-weight: 700;
}

.a4 {
    font-size: 19px;
}

.a5 {
    padding: 0px 0px 0px 0px;
}

.a6 {
    padding: 40px 40px 50px 0px;
}

.a7 {
    font-size:
        17px;
}

.a8 {
    letter-spacing:
        0px;
    text-transform:
        none;
    color:
        #001326;
}

.a9 {
    font-size:
        18px;
    text-transform:
        none;
}

.a10 {
    min-height: 300px;
}

.a11 {
    background-image: 
  url(
"../../../public/assets/wp-content/uploads/2022/01/about-0.jpg");
height: 100vw;
width: 100%;
    background-size: 'cover';
    background-position: center;
}

.a12 {
    padding: 0px 0px 0px 0px;
}

.a13 {
    background-color: #b1dddd;
}

.a14 {
    background-color: #011e2f;
}

.a15 {
    border-width:0px;
}

.a16{
    padding: 140px 0px 70px 70px;
}

.a17{
    background-color: #b1dddd;
}

.a18 {
    letter-spacing:0px;
    text-transform:none;
    color:#001326;
}

.a19 {
    font-size:
        18px;
    text-transform:
        none;
    color: #0a0a0a;
}

.a20 {
    padding: 0px 0px 0px 0px;
}

.a21 {
    padding: 40px 40px 50px 0px;
}

.a22 {
    letter-spacing:
        0px;
    text-transform:
        none;
    color:
        #001326;
}

.a23 {
    font-size:18px;
    text-transform:none;
}

.a24 {
    min-height: 200px;
}

.csr{
    background-image: url(../../../public/assets/wp-content/uploads/2022/01/bg-2.jpg);
  background-size: "cover";
  background-position: "center" ;
  height: 100%;
}

.a26 {
    background-color: #ffffff;
}

.a27 {
    opacity: .5;
    background-image:url(../../../public/assets/wp-content/uploads/2022/01/bg-2.jpg);
    background-size: cover;
    background-position: center;
}

.a28 {
    padding: 10px 10px 10px 10px;
}

.a29 {
    padding: 10px 10px 10px 10px;
}

.a30 {
    background-color: #f9f9f9;
    padding-bottom:30px;
}

.b1 {
    margin: 15px 5px 5px 5px;
    max-width: 100%;
}

.b2 {
    margin-bottom:0px;
}

.b3 {
    font-size:20px;
    padding-top:0px;
}

.b4 {
    text-transform:none;
}

.b5 {
    padding: 10px 10px 10px 10px;
}

.b6 {
    background-color:#ffffff;
    padding-bottom:30px;
}

.b7 {
    margin: 15px 5px 5px 5px;
    max-width: 100%;
}

.b8 {
    margin-bottom:0px;
}

.b9 {
    font-size:20px;
    padding-top:0px;
}

.b10 {
    text-transform:none;
}

.b11 {
    padding: 10px 10px 10px 10px;
}

.b12 {
    padding: 100px 0px 60px 0px;
}

.b13 {
    background-color: #ffffff;
}

.b14 {
    background-size: cover;
    background-position: center;
}

.b15 {
    padding-bottom: 20px;
}

.b16 {
    font-size:35px;
    letter-spacing:0px;
    text-transform:none;
    color:#001326;
}

.b17 {
    padding-bottom:25px;
}

.b18 {
    margin-bottom:
        24px;
}

.b19 {
    color: #dd3333;
    font-size: 25px;
}

.b20 {
    color:#dd3333;
    font-size: 25px;
    width:25px;
}

.b21 {
    font-size:18px;
}

.b22 {
    margin-bottom:
        24px;
}

.b23 {
    color:
        #dd3333;
    font-size:
        25px;
}

.b24 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.b25 {
    font-size:
        18px;
}

.b26 {
    margin-bottom:
        24px;
}

.b27 {
    color:
        #dd3333;
    font-size:
        25px;
}

.b28 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.b29 {
    font-size:
        18px;
}

.b30 {
    margin-bottom:
        24px;
}

.c1 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c2 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c3 {
    font-size:
        18px;
}

.c4 {
    margin-bottom:
        24px;
}

.c5 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c6 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c7 {
    font-size:
        18px;
}

.c8 {
    margin-bottom:
        24px;
}

.c9 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c10 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c11 {
    font-size:
        18px;
}

.c12 {
    margin-bottom:
        24px;
}

.c13 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c14 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c15 {
    font-size:
        18px;
}

.c16 {
    margin-bottom:
        24px;
}

.c17 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c18 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c19 {
    font-size:
        18px;
}

.c20 {
    margin-bottom:
        24px;
}

.c21 {
    color:
        #dd3333;
    font-size:
        25px;
}

.c22 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.c23 {
    font-size:
        18px;
}

.c24 {
    padding-bottom:20px;
}

.c25 {
    font-size: 35px;
    letter-spacing:0px;
    text-transform: none;
    color:#001326;
}

.c26 {
    padding-bottom: 25px;
}

.c27 {
    font-size:17px;
    text-transform:none;
}

.c28 {
    padding-bottom: 25px;
}

.c29 {
    margin-bottom:24px;
}

.c30 {
    color:#dd3333;
    font-size:25px;
}

.d1 {
    color:#dd3333;
    font-size:25px;
    width: 25px;
}

.d2 {
    font-size:18px;
}

.d3 {
    margin-bottom:
        24px;
}

.d4 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d5 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d6 {
    font-size:
        18px;
}

.d7 {
    margin-bottom:
        24px;
}

.d8 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d9 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d10 {
    font-size:
        18px;
}

.d11 {
    margin-bottom:
        24px;
}

.d12 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d13 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d14 {
    font-size:
        18px;
}

.d15 {
    margin-bottom:
        24px;
}

.d16 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d17 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d18 {
    font-size:
        18px;
}

.d19 {
    margin-bottom:
        24px;
}

.d20 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d21 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d22 {
    font-size:
        18px;
}

.d23 {
    margin-bottom:
        24px;
}

.d24 {
    color:
        #dd3333;
    font-size:
        25px;
}

.d25 {
    color:
        #dd3333;
    font-size:
        25px;
    width:
        25px;
}

.d26 {
    font-size:
        18px;
}