/* .infinite-blog-title-wrap {
	background-image: url('../blogs/blogs/02.jpg');
	background-position: center;
	position: relative;
	background-size: cover;
	overflow: hidden;
}

.infinite-blog-title-wrap1{
	background-image: url('../../../../public/assets/static/images/blogs/08.jpg');
	background-position: center;

	background-color: rgba(255, 255, 255, 0.5); 
    opacity: 1;
} */


/* blog title */
.infinite-blog-title-wrap10 {
	background-image: url('../../../../public/assets/static/images/blogs/10.jpg');
	background-position: center;
	position: relative;
	background-size: cover;
	overflow: hidden;
}
.infinite-blog-title-wrap {
	background-image: url('../../../../public/assets/static/images/blogs/10.jpg');
	background-position: center;
	position: relative;
	background-size: cover;
	overflow: hidden;
}
.infinite-blog-title-wrap2 {
	background-image: url('../../../../public/assets/static/images/blogs/01.jpg');
	background-position: center;
	position: relative;
	background-size: cover;
	overflow: hidden;
}

.infinite-blog-title-wrap .infinite-blog-title-container {
	position: relative;
}

.infinite-blog-title-wrap .infinite-blog-title-overlay {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.8;
}

/* .infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-top-overlay {
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	height: 413px;
	opacity: 1;
	background: url("../../../../public/assets/static/images/blogs/08.jpg") center top repeat-x;
}

.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-bottom-overlay {
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 0px;
	height: 413px;
	opacity: 1;
	background: url("../../../../public/assets/static/images/blogs/08.jpg") center bottom repeat-x;
} */

.infinite-blog-title-wrap.infinite-style-small .infinite-blog-title-content {
	padding-top: 93px;
	padding-bottom: 87px;
}

.infinite-blog-title-wrap.infinite-style-large .infinite-blog-title-content {
	padding-top: 184px;
	padding-bottom: 168px;
}

.infinite-blog-title-wrap .infinite-single-article-title {
	font-size: 50px;
	font-weight: 700;
	margin-bottom: 11px;
	letter-spacing: 0.5px;
}

.infinite-blog-title-wrap .infinite-single-article-head-right {
	overflow: hidden;
}

.infinite-blog-title-wrap .infinite-single-article-date-wrapper {
	margin-top: 11px;
}