.o1 {
    padding: 90px 0px 90px 0px;
}

.o2 {
    background-image:url(../../../public/assets/wp-content/uploads/2022/07/locobuzz.jpg);
    background-size: cover;
    background-position: center;
}

.o3 {
    font-size:45px;
    font-weight: 700;
    color: #0a0a0a;
}

.o4 {
    padding-bottom: 0px;
}

.o5 {
    text-transform: none;
    color: #050d1a;
}

.o6 {
    padding-bottom: 0px;
}

.o7 {
    text-transform: none;
    color: #050d1a;
}

.o8 {
    padding: 30px 0px 30px 0px;
}

.o9 {
    padding: 0px 0px 0px 0px;
}

.o10 {
    padding-bottom:0px;
}

.o11 {
    border-width:0px;
}

.o12 {
    padding: 80px 70px 0px 0px;
}

.o13 {
    font-size:35px;
    letter-spacing:0px;
    text-transform:none;
}

.o14 {
    font-size:18px;
    text-transform:none;

}

.o15 {
    padding:60px 0px 60px 0px;
}

.o16 {
    background-color: #e7f7d2;
}

.o17 {
    font-size:35px;
    text-transform:none;
}

.o18 {
    font-style:normal;
}

.o19 {
    padding:130px 0px 80px 0px;
}

.o20 {
    font-size:35px;
    text-transform:none;
}

.o21 {
    padding:60px 30px 0px 0px;
}

.o22 {
    margin: 0px 0px 10px 0px;
}

.o23 {
    margin-top:7px;
}

.o24 {
    font-size: 30px;
    text-transform:none;
}

.o25 {
    text-transform:none;
}

.o26 {
    padding:
        60px 30px 0px 0px;
}

.o27 {
    margin: 0px 0px 10px 0px;
}

.o28 {
    margin-top:
        7px;
}

.o29 {
    font-size:
        30px;
    text-transform:
        none;
}

.o30 {
    text-transform:
        none;
}

.o31 {
    padding:
        60px 30px 0px 0px;
}

.o32 {
    margin: 0px 0px 10px 0px;
}

.o33 {
    margin-top:
        7px;
}

.o34 {
    font-size:
        30px;
    text-transform:
        none;
}

.o34 {
    text-transform:
        none;
}

.o35 {
    padding:
        60px 30px 0px 0px;
}

.o36 {
    margin: 0px 0px 10px 0px;
}

.o39 {
    margin-top:
        7px;
}

.o40 {
    font-size:
        30px;
    text-transform:
        none;
}

.o41 {
    text-transform:
        none;
}

.o42 {
    padding:
        60px 30px 0px 0px;
}

.o43 {
    margin: 0px 0px 10px 0px;
}

.o44 {
    margin-top:
        7px;
}

.o45 {
    font-size:
        30px;
    text-transform:
        none;
}

.o46 {
    text-transform:
        none;
}

.o47 {
    padding:
        60px 30px 0px 0px;
}

.o48 {
    margin: 0px 0px 10px 0px;
}

.o49 {
    margin-top:
        7px;
}

.o50 {
    font-size:
        30px;
    text-transform:
        none;
}

.o51 {
    text-transform:
        none;
}