.allheader{
    margin-left: 100px;
}
  


.linkage{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}
.linkage img{
    margin-left: 15px;
    opacity: 0.3;
}
.linkage img:hover{
    opacity: 2;
}
.current-img{
    opacity: 2 !important;
}
.current-img:hover{
    opacity: 0.5 !important;
}
.all-logos{
    background-color: rgba(247, 246, 246, 0.983) !important;
    /* padding: 2px; */
}

@media screen and (max-width: 800px){
    .all-logos{
        display: none;
    }
    .linkage img{
        display: none;
    }
    .below{
        display: none;
    }
}