.c1 {
    padding: 90px 0px 90px 0px;
}

.c2 {
    /* background-image:url(./Careers.jpg); */
    background-size: cover;
    background-position: center;
}

.c3 {
    font-size: 55px;
    font-weight: 700;
}

.c4 {
    font-size:23px;
    letter-spacing: 0px;
    text-transform:none;
}

.c5 {
    padding: 60px 90px 30px 90px;
}

.c6 {
    background-color: #ededed;
}

.c7 {
    padding: 0px 0px 0px 130px;
}

.c8 {
    font-size:70px;
    font-weight:700;
    text-transform:none;
}

.c9 {
    display:none;
}