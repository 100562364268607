.container{
    margin: 20px;
    
}.grouptickect{
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
}
.group-a{
    position: relative;
    display: flex;
    background-origin: padding-box;
    text-align: left;
}
.left{
    text-align: left;
    position: relative;
    padding: 20px 30px;
    justify-content: center;
    position: relative;
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.redline{
    border-color: #d6000f !important;
    width: 69px;
    height: 1px !important;
    transform-origin: left center;
    margin:0 auto 0 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    border-width: 9px;
    border-style: solid;
    box-sizing: border-box;
}
.event{
    color: #0032d6 !important;
    word-wrap: break-word;
    position: relative;
}
.vmore{
    background-image: none;
    font-family: "Fjalla One";
    font-size: 1.25rem;
    margin: 20px 0 0;
    color: #ffffff !important;
    background-color: #000000 !important;
    border-width: 0;
    vertical-align: middle;
    text-align: center;
    padding: 10px 30px;
    border-radius: 0;
    align-self: flex-start;
    display: table;
    cursor: pointer;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    text-decoration: none;
    border: 0 none transparent;
    outline-width: 0;
}
.group-b{
    
}
.right{
    margin-top: 4rem;
}
.bimage{
    overflow: hidden;
    color: #111111;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: left;
    height: 400%;
    margin: 50px auto 0 0;
    padding-right: 20px;
    object-fit: cover;
    /* display: block; */
    vertical-align: middle;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.tickgroup{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
}
.category{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.categorylist{
    /* display: flex;
    flex-direction: row; */
    margin-top: 5px;
    /* justify-content: space-evenly; */
}
.orderB{
    font-family: "Fjalla One";
    font-size: 1.25rem;
    margin: 20px 0 0;
    color: #ffffff !important;
    background-color: #5f3f3f !important;
    border-width: 0;
    padding: 5px 20px;
    border-radius: 0;
    align-self: center;
    /* display: flex; */
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .group{
    
        display: flex;
        flex-direction: column;
    }
}