.dsource{
    background:transparent;
    padding:0;
    margin:0px auto;
    margin-top:0;
    margin-bottom:0;
}
.npad{
    padding:0px 0px 0px 0px;
}
.dig{
    z-index:6;
    font-family:Source Sans Pro;
}
.dat{
    z-index:5;
}
.transform{
    z-index:6;
    font-family:Source Sans Pro;
}
.indexz{
    z-index:8;font-family:Roboto;
}
.apad{
    /* text-align: center; */
    /* padding-bottom: 60px; */
    
    /* padding:60px 0px 60px 0px */
}
.maxw{
    max-width: 960px ;
}
.pdbot{
    padding-bottom: 0px;
    text-align: center;
}
.item{
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    
}
.fontsty{
    font-style: normal;
    margin-bottom: 25px;
    color: #7c7a7a;
}
.font{
    font-size: 18px;
    text-align: center;
}
.pbot{
    padding-bottom: 50px ;
}
.digital{
    font-size:40px;
    text-transform:none ;
    text-align: center;
}
.digitaldiv{
    padding:60px 0px 60px 0px
}
.div2{
    margin-top: 5px;
    max-width:60px
}
.div3{
    text-transform:none ;
}
.div4{
    padding:60px 60px 60px 60px;
}
.ser{
    background-color: #ededed ;
}
.bck{
    opacity: .5 ;
    background-image:url('../../../public/assets/wp-content/uploads/2022/01/bg-s.png');
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.glr{
    padding-bottom: 50px ;
}
.title{
    font-size:40px;
    text-transform:none;
    text-align: center;
}
.mar{
    margin:10px 10px 10px 10px;
    text-align: center;
}
.pad{
    padding-bottom:30px;
}
.market{
    font-size:20px;
    padding-top:0px;
    font-weight:800;
    letter-spacing:0px;
    text-transform:none ;
}
.social{
    margin:0px 5px 5px 5px;
    max-width:98%;
    margin-left:auto;
    margin-right:auto ;
}
.bus{
    font-size:13px;
    font-style:normal;
    margin-top:0px ;
}
.trans{
    text-transform:none ;
}
.mbot{
    margin-bottom:0px ;
}



/* testimony */

.snip1533 {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #9e9e9e;
    display: inline-block;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    margin: 35px 10px 10px;
    max-width: 310px;
    min-width: 250px;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    border-top: 5px solid #d2652d;
  }
  
  .snip1533 *,
  .snip1533 *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }
  
  .snip1533 figcaption {
    padding: 13% 10% 12%;
  }
  
  .snip1533 figcaption:before {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: #d2652d;
    content: "\f10e";
    font-family: 'FontAwesome';
    font-size: 32px;
    font-style: normal;
    left: 50%;
    line-height: 60px;
    position: absolute;
    top: -30px;
    width: 60px;
  }
  
  .snip1533 h3 {
    color: #3c3c3c;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    margin: 10px 0 5px;
  }
  
  .snip1533 h4 {
    font-weight: 400;
    margin: 0;
    opacity: 0.5;
  }
  
  .snip1533 blockquote {
    font-style: italic;
    font-weight: 300;
    margin: 0 0 20px;
  }


  /* clients */

.clientp {
 
  /* display: flex; */
  /* flex-direction: column; */
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}






/* new */

.partner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.partner img{
  width: 200px;
  height: 100px;
}
.mclient{
  display: none;
}
/* ALL MOBILE RESPONSIVE */
@media screen and (max-width: 800px) {
  .lvclient{
    display: none;
  }
  .mclient{
    display: flex;
    flex-direction: column;
  }
  .partner img{
    width: 120px;
    height: 80px;
  }

}